import React from "react";
import "./ImageItem.scss";

interface ImageItemProps {
  src: string;
  label: string;
  type: string;
  formats: string[];
}

export default class ImageItem extends React.Component<ImageItemProps> {
  render() {
    const { src, label, formats } = this.props;
    return (
      <div className="image-item">
        <div className="image-item--image">
          <img src={src} />
        </div>
        <h2>{label}</h2>
        <div className="info">
          <p>Tilgjengelig som:</p>
          <ul>
            {formats.map((format, idx) => (
              <li key={idx}>{format}</li>
            ))}
          </ul>
          <p>
            Andre størrelser og formater kan avtales etter ønske.{" "}
            <a href="#bestill">Bestilling.</a>
          </p>
        </div>
      </div>
    );
  }
}
