import React, { Component } from "react";
import ImageItem from "./ImageItem";
import "./App.scss";

class GalleryImage {
  constructor(
    public src: string,
    public type: string,
    public label: string,
    public formats: string[]
  ) {}
}

class App extends Component {
  private images: GalleryImage[] = [
    {
      src: "assets/cityscape3.jpg",
      type: "normal",
      label: "Cityscape #3",
      formats: ["Aluplate, 150x50cm"],
    },
    {
      src: "assets/rose5.jpg",
      type: "dual",
      label: "Rose #5",
      formats: ["Aluplate, 50x70cm", "Plakat, 50x70cm"],
    },
    {
      src: "assets/rose4.jpg",
      type: "dual",
      label: "Rose #4",
      formats: ["Aluplate, 50x70cm", "Plakat, 50x70cm"],
    },
    {
      src: "assets/flurry2.jpg",
      type: "normal",
      label: "Flurry #2",
      formats: [
        "Kunsttrykk, 80x40cm",
        "Innrammet kunsttrykk, 100x50cm (80x40cm + passepartout)",
        "Plakat, 80x40cm",
      ],
    },
    {
      src: "assets/vortex1.jpg",
      type: "normal",
      label: "Vortex #1",
      formats: ["Aluplate, 60x80cm", "Plakat, 60x80cm"],
    },
    {
      src: "assets/colorscape1.jpg",
      type: "dual",
      label: "Colorscape #1",
      formats: ["Aluplate, 80x60cm", "Plakat, 80x60cm"],
    },
    {
      src: "assets/colorscape4.jpg",
      type: "dual",
      label: "Colorscape #4",
      formats: [
        "Aluplate, 80x60cm",
        "Plakat, 80x60cm",
        "Finnes også i bredformat (120x50cm)",
      ],
    },
    {
      src: "assets/cityscape1.jpg",
      type: "wide",
      label: "Cityscape #1",
      formats: ["Akryl, 150x50cm"],
    },
    {
      src: "assets/city1.jpg",
      type: "triple",
      label: "City #1",
      formats: ["Akryl, 60x80cm", "Plakat, 60x80cm", "Plakat, 70x100cm"],
    },
    {
      src: "assets/city3.jpg",
      type: "triple",
      label: "City #3",
      formats: ["Akryl, 60x80cm", "Plakat, 60x80cm", "Plakat, 70x100cm"],
    },
    {
      src: "assets/city2.jpg",
      type: "triple",
      label: "City #2",
      formats: ["Akryl, 60x80cm", "Plakat, 60x80cm", "Plakat, 70x100cm"],
    },
    {
      src: "assets/cityscape2.jpg",
      type: "wide",
      label: "Cityscape #2",
      formats: ["Akryl, 150x50cm"],
    },
    {
      src: "assets/tree1.jpg",
      type: "dual",
      label: "Tree #1",
      formats: ["Aluplate, 50x50cm"],
    },
    {
      src: "assets/seave1.jpg",
      type: "dual",
      label: "Seave #1",
      formats: [
        "Kunsttrykk, 40x40cm",
        "Innrammet kunsttrykk, 60x60cm (40x40cm + passepartout)",
        "Aluplate, 50x50cm",
      ],
    },
    {
      src: "assets/seave3.jpg",
      type: "dual",
      label: "Seave #3",
      formats: ["Aluplate, 60x80cm"],
    },
  ];

  render() {
    return (
      <div className="App">
        <nav>
          <a href="#bilder">Galleri</a>
          <a href="#bestill">Bestilling</a>
          <a href="#om">Om Nystad studio</a>
        </nav>
        <a id="bilder" />
        <header>
          <h1>
            <img src="assets/logo.png" alt="Nystad studio" />
          </h1>
          <h2>&ndash; abstrakt digital kunst</h2>
        </header>

        <section>
          {this.images.map((image, idx) => (
            <ImageItem key={idx} {...image} />
          ))}
        </section>

        <section id="bestill" className="text">
          <h3>Jeg vil bestille! Hva gjør jeg?</h3>
          <p>
            Send en e-post til{" "}
            <a href="mailto:post@nystadstudio.no">post@nystadstudio.no</a> med
            detaljene om det du ønsker. Det bør inneholde:
          </p>
          <ul>
            <li>Navn på bildet</li>
            <li>
              Hvilket medium du ønsker:{" "}
              <ul>
                <li>kunsttrykk, aluplate, akrylplate, plakat, etc.</li>
              </ul>
            </li>
            <li>Dimensjoner</li>
          </ul>
          <p>
            Om du vil kan du gjerne beskrive til hvilket formål du ønsker
            bildet, så hjelper jeg å finne det som passer. Jeg forbeholder meg
            retten til å ikke tilby trykk som jeg mener vil gi et dårlig
            resultat eller uttrykk. Se eksemplene på hvert bilde for hva jeg
            mener vil gi gode resultater.
          </p>
          <p>
            Jeg vil gi deg en pris før bestilling blir utført. Normalt vil det
            ta mellom 2-4 uker før bildet er klart, da de fleste bilder blir
            produsert på bestilling. Betaling skjer på forskudd via bank eller
            Vipps, om ikke annet er avtalt.
          </p>
          <p>
            Levering avtales per tilfelle, enten direkte overlevering i og nær
            Oslo, eller sending via post.
          </p>
        </section>

        <section id="om" className="text">
          <h3>Om bildene</h3>
          <p>
            Bildene er originale verker digitalt generert av meg. De trykkes opp
            på profesjonelt vis av tredjepart.
          </p>
          <p>
            Forhåndsvisningene her kan være noe annerledes enn virkeligheten
            pga. oppløsning, komprimering eller fargeinnstillingene på skjermen
            du ser på. Eventuelle vannmerker/logo vil ikke følge med i trykk.
          </p>
          <p>All bruk av bildene skal avtales på forhånd.</p>
        </section>

        <section className="text">
          <h3>Om Nystad studio</h3>
          <p>
            Nystad studio er mitt kunstprosjekt som startet i 2016, hvor jeg
            forener mine digitale ferdigheter som systemutvikler med kreativitet
            og nysgjerrighet.
          </p>
          <p>
            Resultatet er original generativ digital kunst som egner seg godt
            til trykk i storformat i mange forskjellige former.
          </p>
          <p>
            <em>&mdash; Jørgen Nystad</em>
          </p>
        </section>

        <footer>
          <p>Alle bilder er kopibeskyttet. Nystad studio &copy; 2016-2020</p>
          <p>Org.nr: 895 183 512</p>
        </footer>
      </div>
    );
  }
}

export default App;
